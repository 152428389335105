.not-found {
  padding-top: 20px;
  margin: 0 auto;
  max-width: 992px;
}

.not-found img {
  max-width: 100%;
}

.not-found .not-found-title {
  color: #2670e8;
  font-size: 40px;
  font-weight: 700;
}

@media (min-width: 576px) {
  .not-found .not-found-title {
    font-size: 58px;
  }
}

.not-found .not-found-message {
  color: #555555;
  font-size: 16px;
  font-weight: 500;
}

@media (min-width: 576px) {
  .not-found .not-found-message {
    font-size: 18px;
  }
}

.hint .box {
  display: flex;
}

@media (min-width: 576px) {
  .hint .box {
    display: block;
  }
}

.hint .icon {
  color: #2670e8;
  width: 60px;
}

@media (min-width: 576px) {
  .hint .icon {
    display: inline;
  }
}

@media (min-width: 576px) {
  .hint .content {
    display: inline;
  }
}

.hint .title {
  color: #0c326f;
  display: block;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .hint .title {
    display: inline;
  }
}

.hint .message {
  color: #555555;
  font-size: 14px;
  font-weight: 500;
}

@media (min-width: 576px) {
  .hint .vertical-divider {
    border-right: 1px solid #cccccc;
  }
}