.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* app css */
stiloBarraBrasil {
  background-color:'#7F7F7F'; 
  height: 20; 
  padding-top: 0; 
  padding-bottom: 0; 
  padding-right: 0; 
  padding-left: 10 + 'px'; 
  display:'block'
};

stiloMenuBarraTemp{
  list-style-type: "none";
};

estiloLi{
  display:'inline-block'; 
  float: 'left'; 
  padding-right: 10; 
  margin-right: 10;
  border-right: 1; 
  /* solid: '#EDEDED' */
};

estiloLink{
  font-family:'sans;sans-serif';
  text-decoration: 'none';
  color:'white'
};