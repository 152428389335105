.br-alert {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.br-alert .icon {
  align-items: center;
  background: #333333;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  font-size: 175%;
  justify-content: center;
  width: 64px;
}

.br-alert .icon img {
  max-width: 32px;
}

.br-alert .content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 20px;
}

.br-alert .content strong {
  font-weight: 600;
}

.br-alert .content a {
  text-decoration: underline;
}

.br-alert .content *:last-child {
  margin-bottom: 0;
}

.br-alert .close {
  align-items: flex-start;
  -webkit-box-align: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
}

.br-alert .close button {
  background: transparent;
  border: 0;
  padding: 8px 16px;
}

.br-alert.is-success .icon {
  background: #168821;
  color: #ffffff;
}

.br-alert.is-success .content {
  color: #333333;
}

.br-alert.is-success .close button {
  color: #168821;
}

.br-alert.is-danger .icon {
  background: #ff3333;
  color: #ffffff;
}

.br-alert.is-danger .content {
  color: #333333;
}

.br-alert.is-danger .close button {
  color: #ff3333;
}

.br-alert.is-warning .icon {
  background: #ffcd07;
  color: #333333;
}

.br-alert.is-warning .content {
  color: #333333;
}

.br-alert.is-warning .close button {
  color: #ffcd07;
}

.br-alert.is-info .icon {
  background: #155bcb;
  color: #ffffff;
}

.br-alert.is-info .content {
  color: #333333;
}

.br-alert.is-info .close button {
  color: #155bcb;
}

.br-alert.is-inverted .content {
  background: #333333;
  color: #ffffff;
}

.br-alert.is-inverted .content a {
  color: #ffffff;
}

.br-alert.is-inverted .close {
  background: #333333;
}

.br-alert.is-inverted .close button {
  color: #ffffff;
}

.br-alert.is-inverted.is-success .content {
  background: #168821;
}

.br-alert.is-inverted.is-success .close {
  background: #168821;
}

.br-alert.is-inverted.is-danger .content {
  background: #ff3333;
}

.br-alert.is-inverted.is-danger .close {
  background: #ff3333;
}

.br-alert.is-inverted.is-warning .content {
  background: #ffcd07;
  color: #333333;
}

.br-alert.is-inverted.is-warning .content a {
  color: #333333;
}

.br-alert.is-inverted.is-warning .close {
  background: #ffcd07;
}

.br-alert.is-inverted.is-warning .close button {
  color: #333333;
}

.br-alert.is-inverted.is-info .content {
  background: #155bcb;
}

.br-alert.is-inverted.is-info .close {
  background: #155bcb;
}

.content {
  padding-right: 0px;
  padding-left: 0px;
  font-size: 14px;
  padding-top: 12px;
}

.content .serpro {
  font-weight: bold;
}

#off {
  font-size: 16px;
  color: #C9302C;
  padding-left: 5px;
}

#on {
  font-size: 16px;
  color: #449D44;
  padding-left: 5px;
}

.status-message {
  font-weight: bold;
}

.alert {
  margin-top: 20px;
}