#avatar-true {
  float: left; 
  width: 50px; 
  height: 50px; 
  border-radius: 50%; 
  margin-right: 12px;
  font-size: 10px;
}

#avatar-font-title {
  font-size: 14px;
}

#avatar-font-text {
  font-size: 14px;
  font-style: italic;
}

#avatar-false {
  font-size: 30px;
  margin-right: 12px;
}