.card {
    background: #f3f3f3 0 0 no-repeat padding-box;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    padding: 20px;
    margin: 20px;
    justify-content: center;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}   

.card-icon {
    margin-top: 25px;
    font-size: 60px;
    color: #0c326f;
}

.card-footer {
    padding-top: 5px;;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    color: #0c326f;
    width: 100%;
    border-top: solid #0c326f;
    margin-top: 35px;
}

.home-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.home-cards .card {
    height: 230px;
    width: 180px;
    padding-top: 0!important;
    align-content: flex-start;
}
