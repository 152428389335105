.back-button {
	margin-right: 15px;
}

.content-title {
	font-weight: bold;
	padding-left: 12px;
	padding-top: 12px;
}

.status {
	padding: 0px;
	padding-right: 12px;
	padding-left: 12px;
}

.div-form {
	padding-top: 20px;
}

.status {
	padding-top: 30px;
}

.actions-button {
	margin-top: -30px;
}

.generate-key {
	padding-top: 12px;
}

#content {
	margin-bottom: 40px;
	margin-top: 12px;
}

#off {
	font-size: 16px;
	color: #C9302C;
	padding-left: 5px;
}
  
#on {
	font-size: 16px;
	color: #449D44;
	padding-left: 5px;
}

.options {
	margin: 20px
}

.pick {
	border: 1px solid #888;
	border-radius: 6px;
}
.picky__input {
	background: #fff;
	border: 1px solid #888;
	border-radius: 6px;
	color: #555;
	display: block;
	font-size: 16px;
	font-weight: 400;
	padding: 0px 0px;
	width: 100%;
}

.botaoNovo { 
	margin-right: 12px !important;
}