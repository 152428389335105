.link {
	margin-right: 15px;
}

.back-button {
	margin-right: 15px;
}

.content-title {
	font-weight: bold;
	padding-left: 12px;
	padding-top: 12px;
}

.status {
	padding: 0px;
	padding-right: 12px;
	padding-left: 12px;
}

.div-form {
	padding-top: 20px;
}

.status {
	padding-top: 30px;
}

.actions-button {
	margin-top: -30px;
}

#content {
	margin-bottom: 40px;
	margin-top: 12px;
}

#danger {
	font-size: 16px;
	color: #C9302C;
	padding-left: 5px;
}

.delete-file {
	border: none;
	background-color:white;
}

.history-table {
    color: #888;
    font-size: 14px;
    font-weight: 500;
	width: 100%;
}

.attachments-table {
    color: #888;
    font-size: 14px;
    font-weight: 500;
	margin-left: 30px;
	width: 60%;
	border: 1px solid;
	border-collapse: collapse;
}

.attachments-table td, 
.attachments-table th {
	border: 1px solid;
	padding-top: 7px;
	padding-left: 2px;
}

.attachments-fieldset {
	margin-left: 30px;
}

.attachments-fieldset legend {
	color: #333;
    font-family: Rawline;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.action-td {
	align-content: center;
}

td.action-td button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: underline;
	display: inline;
	margin: 0;
	color: #1351b4;
}

.ajuda-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: underline;
	display: inline;
	margin: 0;
	color: #1351b4;
}

.textarea-texto-padro-finalidade {
    width: 100%;
    height: 300px;
	text-align: left;
}

.input-bold {
	font-weight: bold !important;
}

