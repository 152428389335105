.link {
	margin-right: 15px;
}

.back-button {
	margin-right: 15px;
}

.content-title {
	font-weight: bold;
	padding-left: 12px;
	padding-top: 12px;
}

.status {
	padding: 0px;
	padding-right: 12px;
	padding-left: 12px;
}

.div-form {
	padding-top: 20px;
}

.texto-alinhado-direita {
	text-align: right
}

.caixa-texto {
	background: #fff;
    border: 1px solid #888;
    border-radius: 6px;
    color: #555;
    display: block;
    font-size: 16px;
    font-weight: 400;
    padding: 8px 16px;
    width: 100%;
}

.status {
	padding-top: 30px;
}

.actions-button {
	margin-top: -30px;
}

#content {
	margin-bottom: 40px;
	margin-top: 12px;
}

.download-container {
	display: flex;
}

.button-download {
	background: 0 0;
    border: 0;
    color: #1351b4;
    text-decoration: none;
}

