.logo__item {
  margin-left: 16px;
  margin-right: 20px;
  height: 50px;
  width: auto;
}

.form-search {
  right: 100px;
  position: absolute;
} 

