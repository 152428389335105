.link {
	margin-right: 15px;
}

.back-button {
	margin-right: 15px;
}

.content-title {
	font-weight: bold;
	padding-left: 12px;
	padding-top: 12px;
}

.status {
	padding: 0px;
	padding-right: 12px;
	padding-left: 12px;
}

.div-form {
	padding-top: 20px;
}

.status {
	padding-top: 30px;
}

.actions-button {
	margin-top: -30px;
}

#content {
	margin-bottom: 40px;
	margin-top: 12px;
}

.bolded td{
	font-weight: bold !important;
}