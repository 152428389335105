.link {
	margin-right: 15px;
}

.back-button {
	margin-right: 15px;
}

.content-title {
	font-weight: bold;
	padding-left: 12px;
	padding-top: 12px;
}

.status {
	padding: 0px;
	padding-right: 12px;
	padding-left: 12px;
}

.div-form {
	padding-top: 20px;
}

.status {
	padding-top: 30px;
}

.actions-button {
	margin-top: -30px;
}

#content {
	margin-bottom: 40px;
	margin-top: 12px;
}

tr.tr-bold td { 
	font-weight: bold;
}

tr.tr-totalizacao-orgao-api-metodo td { 
	font-weight: bold;
	background-color: #f3f7fc;
}

tr.tr-totalizacao-orgao-api td { 
	font-weight: bold;
	background-color: #deebf7;
}

tr.tr-totalizacao-api td{ 
	font-weight: bold;
	background-color: #c6dbef;
}



