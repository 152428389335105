.barra-brasil {
  background-color: #7F7F7F;
  height: 20px; 
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 10px;
  display: block;
}

.barra-brasil li {
  display: "inline";
  float: left; 
  padding-right: 10px; 
  margin-right: 10px; 
  border-right: 1px solid #EDEDED;
}

.barra-brasil li a {
  font-family: "sans,sans-serif"; 
  text-decoration: none;
  color: #ffffff;
}

.menu-barra-temp {
  list-style-type: none;
}