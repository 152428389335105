.react-pdf__Document {
    box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
}
.react-pdf__Page {
    margin: 0 auto;
    /*min-width: 380px;*/
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 1000% !important;
}